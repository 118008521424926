import React from "react";

export const Subscribe = (props) => {
  return (
    <div id="subscribe" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Subscribe</h2>
          
          <div className='emailcontainer' >
            {/* add the mailer lite content here */}
            <div class="ml-embedded" data-form="ChBkcN"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
